/* eslint-disable react/prop-types */
import React from 'react'
import './Loader.css'
import mainLogo from '../assets/mermaid-icon.svg'

export const Loader = ({ message }) => {
  return (
    <div className="loader">
      <img src={mainLogo} alt="mermaid logo" style={{ padding: '20px' }} />
      <span className="spinner"></span>
      <span className="loader-message">{message}</span>
    </div>
  )
}
